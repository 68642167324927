// This file is used to import all SCSS files and maybe other file types
// 3th party packages
import Vue from 'vue'
import { generateAxiosInstance } from '../helpers/axios_provider';
import { bus } from '../entrypoints/bus.js'
import moment from 'moment';
import globalDirectives from "@/helpers/globalDirectives";
import { trackClick } from "@/helpers/clickTracker";

Vue.config.ignoredElements = ['vue-advanced-chat']

Vue.use(globalDirectives);
window.trackClick = trackClick;

Vue.prototype.$axios = generateAxiosInstance();
Vue.prototype.$bus = bus;
Vue.prototype.$moment = moment;
Vue.prototype.$config = config;
Vue.prototype.$alerts = alerts;
Vue.prototype.$headers = {  //  Header to use on axios request
    Accept: "application/json",
    ApiKey: "99296b00807e5fe3931bdb0b7b48b32f",
    "Content-Type": "application/json",
    "x-api-key": "9c6334a7-8215-46ff-8ec9-f442cdc43cac"
};